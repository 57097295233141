import { Navigate } from 'react-router-dom'

import InvoiceList from '../../components/invoices/list'
import { useAuth } from '../../context/auth'
import Chatwoot from '../../components/chat/chatwoot'
import config from '../../config'

const DashboardPage = () => {
  const { user } = useAuth()

  if(!user.businessDetails || !user.businessDetails.name) {
    return <Navigate to='/onboard-step-1' />
  }

  return (
    <>
      {config.ENV === 'production' && <Chatwoot />}
      <InvoiceList />
    </>
  )
}

export default DashboardPage
