import React from 'react'
import { Link } from 'react-router-dom'

import Button, { ButtonSize, ButtonVariant } from '../ui/button'
import './logo.scss'

type Props = { imageSrc?: string, allowEdit?: boolean }
const BusinessLogo: React.FC<Props> = ({ imageSrc, allowEdit = true }) => {
  const renderEditLogoButton = (label: string) => (
    <Link to='/profile/logo'><Button variant={ButtonVariant.LIGHT} size={ButtonSize.SMALL}>{label}</Button></Link>
  )
  if(allowEdit && !imageSrc) {
    return (
      <div className='d-flex justify-content-center align-items-center business-logo' style={{ width: '100%', height: '100%', border: '1px dashed #ccc' }}>
        {renderEditLogoButton('Add Logo')}
      </div>
    )
  }
  return (
    <div className='business-logo text-center mx-auto'>
      <img src={imageSrc} alt='Business Logo' />
      {allowEdit && <div className='mt-2 text-center w-full'>{renderEditLogoButton('Edit Logo')}</div>}
    </div>
  )
}

export default BusinessLogo
