import config from '../config'
import fetcher from './fetcher.mjs'

export const getUsers = async () => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}/api/admin/users`, {
      method: 'GET'
    })
    return res
  } catch (error: any) {
    return null
  }
}

export const getCurrentUser = async () => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}/api/user`, {
      method: 'GET'
    })
    return res
  } catch (error: any) {
    return null
  }
}

export const getClients = async () => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}/api/clients`, {
      method: 'GET'
    })
    return res.data
  } catch (error: any) {
    return null
  }
}
export const getClientById = async (url: string) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'GET'
  })
  return res.data
}

export const removeClientById = async (url: string, { arg }: { arg: { params: any } }) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}/${arg.params.id}`, {
    method: 'DELETE'
  })
  return res
}

export const getClientBalance = async (url: string) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'GET'
  })
  return res.data
}

export const getInvoices = async (url: string) => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
      method: 'GET'
    })
    return res.data
  } catch (error: any) {
    return null
  }
}

export const getCurrencies = async () => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}/api/invoices/currencies`, {
      method: 'GET'
    })
    return res.data
  } catch (error: any) {
    return null
  }
}

export const getDefaultCurrency = async () => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}/api/invoices/default-currency`, {
      method: 'GET'
    })
    return res.data
  } catch (error: any) {
    return null
  }
}

export const getInvoiceById = async (url: string) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'GET'
  })
  return res.data
}

export const logout = async () => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}/api/auth/logout`, {
      method: 'GET'
    })
    return res
  } catch (error: any) {
    return null
  }
}

export const updateBusinessDetails = async (url: string, { arg }: { arg: { body: any } }) => {
  const formattedBody: any = {}
  Object.keys(arg.body).forEach((key) => {
    let formattedKey = key.replace('businessDetails.', '')
    if(formattedKey.startsWith('tax.')) {
      formattedKey = formattedKey.replace('tax.', '')
      formattedBody.tax = { ...formattedBody.tax, [formattedKey]: arg.body[key] }
    } else if (formattedKey.startsWith('currency.')) {
      formattedKey = formattedKey.replace('currency.', '')
      formattedBody.currency = { ...formattedBody.currency, [formattedKey]: arg.body[key] }
    } else {
      formattedBody[formattedKey] = arg.body[key]
    }
  })
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
      method: 'PATCH',
      body: JSON.stringify(formattedBody)
    })
    return res
  } catch (error: any) {
    return null
  }
}

export const saveOrUpdateClient = async (url: string, { arg }: { arg: { body: any } }) => {
  const formattedBody: any = {}
  Object.keys(arg.body).forEach((key) => {
    const formattedKey = key.replace('client.', '')
    formattedBody[formattedKey] = arg.body[key]
  })
  let method = ''
  if(formattedBody._id) {
    method = 'PATCH'
  } else {
    method = 'POST'
  }
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method,
    body: JSON.stringify(formattedBody)
  })
  return res
}

export const saveOrUpdateInvoice = async (url: string, { arg }: { arg: { body: any } }) => {
  let method = ''
  if(arg.body._id) {
    method = 'PATCH'
  } else {
    method = 'POST'
  }

  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method,
    body: JSON.stringify(arg.body)
  })
  return res
}

export const getClonedInvoice = async (url: string) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'GET'
  })
  return res
}

export const removeInvoice = async (url: string) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'DELETE',
  })
  return res
}

export const sendInvoiceEmail = async (url: string, { arg }: { arg: { body: { invoiceId: string, content: string } } }) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify(arg.body)
  })
  return res
}
export const getEmailTemplate = async (url: string) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'GET',
  })
  return res
}

export const getNextCode = async () => {
  try {
    const res = await fetcher(`${config.REACT_APP_API_URL}/api/invoices/next-code`, {
      method: 'GET'
    })
    return res
  } catch (error: any) {
    return null
  }
}

export const getInvoiceHistory = async (url: string) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'GET'
  })
  return res
}

export const updateReminders = async (url: string, { arg }: { arg: { body: any } }) => {
  const res = await fetcher(`${config.REACT_APP_API_URL}/api/user/reminders`, {
    method: 'PATCH',
    body: JSON.stringify(arg.body)
  })
  return res
}

export const playgroundCall = async (_: string, { arg }: { arg: { apiPath: string } }) => {
  if(arg.apiPath !== '') {
    const res = await fetcher(`${config.REACT_APP_API_URL}${arg.apiPath}`, {
      method: 'GET',
    })
    return res
  }
}
