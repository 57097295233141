import React from 'react'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'

import Input from '../ui/input'
import Button, { ButtonSize, ButtonVariant } from '../ui/button'
import { useAuth } from '../../context/auth'
import { useAlerts } from '../../context/alerts'
import { getDefaultCurrency, updateBusinessDetails } from '../../api'
import TextArea from '../ui/input/textArea'
import * as utils from '../../utils'
import Checkbox from '../ui/input/checkbox'
import CurrencySelector from '../invoices/currencySelector'
import useSWR from 'swr'
import FakeInvoice from '../ui/fakeInvoice'
import BusinessLogo from './logo'

/**
 * Form to create/edit business details of user.
 */
type Props = {
  autofocusField?: string // name of field to autofocus
  redirectAfterSaveTo?: string // path to redirect after save
  onCancel?: () => void
  saveLabel?: string
  cancelLabel?: string
  silent?: boolean // if true no alert is shown after save
  showFakeInvoice?: boolean
}
const BusinessDetailsForm: React.FC<Props> = ({ showFakeInvoice, silent, autofocusField, redirectAfterSaveTo, onCancel, saveLabel = 'Save', cancelLabel = 'Back'}) => {
  const navigate = useNavigate()
  const { user, mutate } = useAuth()
  const [values, setValues] = React.useState({
    'businessDetails.name': '',
    'businessDetails.email': user?.businessDetails?.email || user?.email || '',
    'businessDetails.billingAddress': '',
    'businessDetails.paymentInstructions': '',
    'businessDetails.tax.name': '',
    'businessDetails.tax.percentage': '15',
    'businessDetails.enableTax': '',
    'businessDetails.currency.code': user?.businessDetails?.currency?.code,
    'businessDetails.logoSrc': user?.businessDetails?.logoSrc,
  })
  const { trigger, isMutating } = useSWRMutation('/api/user/business', updateBusinessDetails)
  const { data: getDefaultCurrencyData } = useSWR('/api/invoices/currencies/default', getDefaultCurrency)
  const { addAlert } = useAlerts()

  React.useEffect(() => {
    if(user && user.businessDetails) {
      const formattedData = utils.flattenObject(user)
      setValues(formattedData)
    }
    if(!user.businessDetails?.currency?.code) {
      setValues(v => ({ ...v, 'businessDetails.currency.code': getDefaultCurrencyData?.code }))
    }
  }, [user, getDefaultCurrencyData])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    try {
      await trigger({ body: values })
      mutate()
      if(!silent) {
        addAlert({ type: 'info', message: 'Your details have been updated' })
      }

      if(redirectAfterSaveTo) {
        return navigate(redirectAfterSaveTo)
      } else {
        return navigate('/profile')
      }
    } catch(error) {
      console.log('error', error)
    }
  }

  const handleTextChange = (e: any) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const handleToggleTax = (e: any) => {
    const { name, checked } = e.target
    setValues({ ...values, [name]: checked })
  }

  return (
    <div className='row'>
      <div className={`${showFakeInvoice ? 'col-12 col-lg-4' : 'col'}`}>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-9'>

              <div className='mb-4'>
                <Input
                  label={`Your business name`}
                  autoFocus={autofocusField === 'businessDetails.name'}
                  id='businessDetails.name'
                  defaultValue={values['businessDetails.name']}
                  name='businessDetails.name'
                  onChange={handleTextChange}
                  required
                  tooltip='This will be shown on your invoices and emails sent to your clients.'
                  maxLength={150}
                />
              </div>

              <div className='mb-4'>
                <Input
                  label={`Your business email`}
                  type='email'
                  id='businessDetails.email'
                  name='businessDetails.email'
                  defaultValue={values['businessDetails.email']}
                  onChange={handleTextChange}
                  required
                  tooltip='Your clients will be able to email you at this address.'
                  maxLength={150}
                />
              </div>
            </div>
            {!showFakeInvoice && (
              <div className='col-2 mx-auto'>
                <BusinessLogo imageSrc={values['businessDetails.logoSrc']} />
              </div>
            )}
          </div>
          <div className='mb-4'>
            <Input
              label={`Billing Address`}
              id='businessDetails.billingAddress'
              name='businessDetails.billingAddress'
              defaultValue={values['businessDetails.billingAddress']}
              onChange={handleTextChange}
              maxLength={250}
            />
          </div>
          <div className='mb-2'>
            <Checkbox
              label={`Add Tax To Invoices`}
              id='businessDetails.enableTax'
              name='businessDetails.enableTax'
              defaultValue={values['businessDetails.enableTax']}
              onChange={handleToggleTax}
              checked={values['businessDetails.enableTax']}
            />
          </div>
          {values['businessDetails.enableTax'] && (
            <div className='row mb-4'>
              <div className='col-4'>
                <Input
                  label={`Tax Name`}
                  placeholder={`IVA, VAT, GST`}
                  id='businessDetails.tax.name'
                  name='businessDetails.tax.name'
                  defaultValue={values['businessDetails.tax.name']}
                  onChange={handleTextChange}
                />
              </div>
              <div className='col-3'>
                <Input
                  label='Tax %'
                  type='number'
                  id='businessDetails.tax.percentage'
                  name='businessDetails.tax.percentage'
                  defaultValue={values['businessDetails.tax.percentage']}
                  onChange={handleTextChange}
                />
              </div>
            </div>
          )}
          <div className='mb-4'>
            <CurrencySelector onChange={handleTextChange} defaultValue={values['businessDetails.currency.code']} />
          </div>
          <div className='mb-4'>
            <TextArea
              rows={4}
              label='Payment Instructions'
              id='businessDetails.paymentInstructions'
              name='businessDetails.paymentInstructions'
              defaultValue={values['businessDetails.paymentInstructions']}
              onChange={handleTextChange}
              tooltip='Describe how your clients should pay you.'
              maxLength={350}
            />
          </div>
          <div className='d-flex justify-content-between pt-4'>
            <Button variant={ButtonVariant.LIGHT} size={ButtonSize.LARGE} disabled={!onCancel || isMutating} type='button' onClick={onCancel}>{cancelLabel}</Button>
            <Button size={ButtonSize.LARGE} loading={isMutating} type='submit'>{saveLabel}</Button>
          </div>
        </form>
      </div>
      {showFakeInvoice && (
        <FakeInvoice
          businessName={values['businessDetails.name']}
          businessAddress={values['businessDetails.billingAddress']}
          businessEmail={values['businessDetails.email']}
          paymentInstructions={values['businessDetails.paymentInstructions']}
          logoSrc={user?.businessDetails?.logoSrc}
        />
      )}
    </div>
  )
}

export default BusinessDetailsForm
