import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Footer from './footer'
import Hero from './hero'
import { useAuth } from '../../context/auth'

const LandingPage = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if(user && location.pathname === '/' && localStorage.getItem('auth_redirect_path')) {
      const redirectPath = localStorage.getItem('auth_redirect_path') ?? '/dashboard'
      localStorage.removeItem('auth_redirect_path')
      navigate(redirectPath, { replace: true })
    }
  }, [user])

  return (
    <div>
      <Hero />
      <Footer />
    </div>
  )
}

export default LandingPage
