import { NavLink, useNavigate } from 'react-router-dom'

import Step from '../../components/ui/stepper/step'
import BusinessLogo from '../user/business-logo'
import Button, { ButtonSize, ButtonVariant } from '../../components/ui/button'
import { useAuth } from '../../context/auth'

const Step3NewClient = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  const onGoBack = () => {
    navigate('/onboard-step-1')
  }
  const onNext = () => {
    navigate('/onboard-step-3')
  }
  return (
    <Step
      subHeading={`Let's add your business logo.`}
      current={2}
      total={4}
    >
      <div className='container-sm mx-auto'>
        <BusinessLogo />
        {!user.businessDetails?.logoSrc && (
          <div className='text-center'>
            <NavLink to='/onboard-step-3' className='btn btn-link text-muted'>
                I'll add my logo later
            </NavLink>
          </div>
        )}
        <div className='d-flex justify-content-between pt-4'>
          <Button variant={ButtonVariant.LIGHT} size={ButtonSize.LARGE} type='button' onClick={onGoBack}>Go Back</Button>
          <Button size={ButtonSize.LARGE} type='submit' onClick={onNext}>Next</Button>
        </div>
      </div>
    </Step>
  )
}

export default Step3NewClient
