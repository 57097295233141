import React from 'react'
import ImageFilePicker from '../../components/ui/input/imageFilePicker'
import { useAlerts } from '../../context/alerts'
import './business-logo.scss'
import { useAuth } from '../../context/auth'

const BusinessLogo = () => {
  const { addAlert } = useAlerts()
  const { user, mutate } = useAuth()
  return (
    <div className='logo-picker-container'>
      <ImageFilePicker
        buttonText='Save'
        initialImage={user?.businessDetails?.logoSrc}
        apiEndpoint='/api/user/business/logo'
        onUploadSuccess={() => {
          addAlert({ type: 'info', message: 'Your business logo has been updated' })
          mutate()
        }}
        onUploadError={(error) => addAlert({ type: 'error', message: error.message })}
      />
    </div>
  )
}

export default BusinessLogo
