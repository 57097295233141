import React from 'react'

import Input from '../ui/input'
import Button, { ButtonSize, ButtonVariant } from '../ui/button'
import { TClientForm } from '../../types'
import { useAlerts } from '../../context/alerts'
import FakeInvoice from '../ui/fakeInvoice'
import { useAuth } from '../../context/auth'
import useSWRMutation from 'swr/mutation'
import { saveOrUpdateClient } from '../../api'


type Props = {
  defaultValues?: TClientForm
  onSave: (clientId: string) => void // this will be called after save or udpate
  onCancel: () => void
  saveLabel?: string
  cancelLabel?: string
  silent?: boolean // if true no alert is shown after save
  showFakeInvoice?: boolean
  defaultPristine?: boolean // if true save button is always enabled
}
/**
 * Form to create/edit a client.
 */
const emptyValues: TClientForm = {
  'client.tradingName': '',
  'client.billingAddress': '',
  'client.name': '',
  'client.lastName': '',
  'client.email': ''
}
const ClientForm: React.FC<Props> = ({ showFakeInvoice, silent, defaultValues = emptyValues, onSave, onCancel, saveLabel ='Save', cancelLabel='Cancel', defaultPristine = true }) => {
  const { trigger: triggerSaveOrUpdate, isMutating: isLoading } = useSWRMutation('/api/clients', saveOrUpdateClient)

  const [values, setValues] = React.useState(defaultValues)
  const { user } = useAuth()
  const [pristine, setPristine] = React.useState(defaultPristine)
  const { addAlert } = useAlerts()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const response = await triggerSaveOrUpdate({ body: values })
    setPristine(true)
    if(!silent) {
      addAlert({ type: 'info', message: 'Client has been saved' })
    }
    const clientId = response?.data?.insertedId
    onSave(clientId)
  }

  const handleTextChange = (e: any) => {
    const { name, value } = e.target
    setPristine(false)
    setValues({ ...values, [name]: value })
  }

  return (
    (
      <div className='row'>
        <div className={`${showFakeInvoice ? 'col-12 col-lg-4' : 'col'}`}>
          <form onSubmit={handleSubmit}>
            <div className='mb-3'>
              <Input autoFocus label='Trading Name' id='client.tradingName' defaultValue={values['client.tradingName']} name='client.tradingName' onChange={handleTextChange} required />
            </div>
            <div className='mb-3'>
              <Input
                label='Email'
                id='client.email'
                name='client.email'
                defaultValue={values['client.email']}
                onChange={handleTextChange}
                required
                type='email'
                tooltip='This is the email address where the invoice will be sent.'
              />
            </div>
            <div className='mb-3'>
              <Input
                label='Contact Name'
                id='client.name'
                placeholder='Mick'
                name='client.name'
                defaultValue={values['client.name']}
                onChange={handleTextChange}
              />
            </div>
            <div className='mb-3'>
              <Input
                label='Contact Last Name'
                id='client.lastName'
                placeholder='Jagger'
                name='client.lastName'
                defaultValue={values['client.lastName']}
                onChange={handleTextChange}
              />
            </div>
            <div className='mb-3'>
              <Input
                label='Billing Address'
                id='client.billingAddress'
                name='client.billingAddress'
                defaultValue={values['client.billingAddress']}
                onChange={handleTextChange}
                tooltip='This billing address will be shown in the invoices.'
              />
            </div>
            <div className='d-flex justify-content-between pt-4'>
              <div>
                <Button disabled={isLoading} size={ButtonSize.LARGE} variant={ButtonVariant.LIGHT} type='button' onClick={onCancel}>{cancelLabel}</Button>
              </div>
              <div>
                <Button size={ButtonSize.LARGE} loading={isLoading} loadingText='Saving...' type='submit' disabled={isLoading || pristine}>{saveLabel}</Button>
              </div>
            </div>
          </form>
        </div>
        {showFakeInvoice && (
          <FakeInvoice
            businessName={user?.businessDetails?.name}
            businessAddress={user?.businessDetails?.billingAddress}
            businessEmail={user?.businessDetails?.email}
            paymentInstructions={user?.businessDetails?.paymentInstructions}
            clientName={values['client.tradingName']}
            clientAddress={values['client.billingAddress']}
            clientEmail={values['client.email']}
            logoSrc={user?.businessDetails?.logoSrc}
          />
        )}
      </div>
    )
  )
}

export default ClientForm
