import useSWR from 'swr'
import { useNavigate, useParams } from 'react-router-dom'

import { getClientById, removeClientById } from '../../api'
import ClientForm from '../../components/clients/form'
import { parseClientToForm } from '../../lib/parsers'
import ClientBalance from '../../components/clients/balance'
import useSWRMutation from 'swr/mutation'
import { useAlerts } from '../../context/alerts'
import Button, { ButtonVariant } from '../../components/ui/button'

const EditClientPage = () => {
  const { id } = useParams()
  const { data, mutate, error, isLoading } = useSWR(`/api/clients/${id}`, getClientById)
  const { addAlert } = useAlerts()
  const {
    trigger: triggerDeleteClient,
    isMutating: isDeletingClient,
    error: deleteClientError,
  } = useSWRMutation('/api/clients', removeClientById)

  const navigate = useNavigate()

  if(!data || error){
    return <div>Error ocurred.</div>
  }
  if(id === undefined) return <div>Client id is required</div>
  if(isLoading) return <div>Loading...</div>

  const onSave = async () => {
    mutate()
    return navigate(-1)
  }

  const onCancel  = () => {
    return navigate(-1)
  }

  const handleDelete = async () => {
    if(id && id?.toString().trim() !== '') {
      if (window.confirm(`Are you sure you want to delete: "${data.tradingName}"`) === true) {
        await triggerDeleteClient({ params: { id } })
        addAlert({ type: 'info', message: 'Client deleted' })
        navigate('/dashboard', { replace: true })
      }
    }
  }

  return (
    <div className='col-lg-6'>
      <ClientForm
        onCancel={onCancel}
        defaultValues={parseClientToForm(data)}
        onSave={onSave}
      />
      <div className='mt-5' />
      <ClientBalance />
      <div className='alert alert-danger mt-5'>
        <b>Danger Zone</b>
        <hr />
        <div className='d-flex align-items-center'>
          <span className='me-4'>Delete this client and all its invoices</span>
          <Button variant={ButtonVariant.LIGHT} disabled={isDeletingClient} onClick={handleDelete}>Delete</Button>
        </div>
        {deleteClientError && <div>{deleteClientError.toString()}</div>}
      </div>
    </div>
  )
}

export default EditClientPage
