import React, { useState, useRef } from 'react'
import useSWRMutation from 'swr/mutation'

import fetcher from '../../../api/fetcher.mjs'
import config from '../../../config'
import Button, { ButtonSize, ButtonVariant } from '../button'
import Spinner from '../spinner'
import { useAlerts } from '../../../context/alerts'
import IconButton from '../button/icon'
import { ReactComponent as TrashIcon } from '../../../static/images/trash.svg'

interface ImageFilePickerProps {
  initialImage?: string
  apiEndpoint: string
  onUploadSuccess?: (response: any) => void
  onUploadError?: (error: Error) => void
  buttonText?: string
  fileLimit?: number // in bytes
}

// Define the fetcher function for useSWRMutation
async function uploadImageFetcher(url: string, { arg }: { arg: { logo: string } }) {
  return fetcher(`${config.REACT_APP_API_URL}${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(arg)
  })
}

const ImageFilePicker: React.FC<ImageFilePickerProps> = ({
  initialImage,
  apiEndpoint,
  onUploadSuccess,
  onUploadError,
  buttonText = 'Upload Image',
  fileLimit = 1e+6
}) => {
  const { addAlert } = useAlerts()
  const [previewImage, setPreviewImage] = useState<string>(initialImage || '')
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  // Setup useSWRMutation
  const { trigger, isMutating } = useSWRMutation(
    apiEndpoint,
    uploadImageFetcher,
    {
      onSuccess: (data) => {
        setIsUploading(false)
        if (onUploadSuccess) {
          onUploadSuccess(data)
          fileInputRef.current!.value = ''
        }
      },
      onError: (error) => {
        setIsUploading(false)
        if (onUploadError) onUploadError(error)
      }
    }
  )

  // Accepted image formats
  const acceptedFormats = 'image/png,image/jpeg,image/jpg,image/svg+xml,image/webp,image/avif'

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files[0]) {
      const file = files[0]
      if(file.size > fileLimit) {
        const mbSize = fileLimit / 1e+6
        addAlert({ type: 'error', message: `File size should be less than ${mbSize}MB` })
        event.target.value = ''
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        const result = reader?.result as string
        setPreviewImage(result)
      }

      reader.readAsDataURL(file)
    }
  }

  const convertFileToBase64 = async (file:File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

  const removeCurrentImage = () => {
    fileInputRef.current!.value = ''
    setPreviewImage(initialImage || '')
  }

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleUpload = async () => {
    if (!previewImage) return

    try {
      // Extract the base64 data (remove the data:image/xxx;base64, prefix)
      const fileSelected = fileInputRef?.current?.files?.[0]
      if(fileSelected) {
        setIsUploading(true)
        const logoBase64 = await convertFileToBase64(fileSelected)
        // const base64Data = previewImage.split(',')[1]
        // Trigger the mutation
        await trigger({ logo: logoBase64 })
      }
    } catch (error) {
      console.error('Error uploading image:', error)
      setIsUploading(false)
      if (onUploadError) onUploadError(error as Error)
    }
  }

  return (
    <div className='d-flex flex-column align-items-center'>
      <div className='position-relative' style={{ width: '200px', height: '200px' }}>
        {/* Hidden file input */}
        <input
          type='file'
          className='d-none'
          ref={fileInputRef}
          onChange={handleFileChange}
          accept={acceptedFormats}
          disabled={isMutating || isUploading}
        />

        {/* Image preview area */}
        <div
          className='border rounded position-relative overflow-hidden d-flex justify-content-center align-items-center'
          style={{
            width: '100%',
            height: '100%',
            cursor: isUploading ? 'wait' : 'pointer'
          }}
          onClick={!isUploading ? handleClick : undefined}
        >
          {previewImage ? (
            <img
              src={previewImage}
              alt='Selected'
              className='img-fluid'
              style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
            />
          ) : (
            <div className='text-center text-muted p-3'>No image selected</div>
          )}

          {/* Hover overlay */}
          <div
            className='position-absolute w-100 h-100 top-0 start-0 d-flex justify-content-center align-items-center'
            style={{
              background: 'rgba(0, 0, 0, 0.8)',
              opacity: isUploading ? 1 : 0,
              transition: 'opacity 0.3s',
              color: 'white',
              fontWeight: 'bold'
            }}
            onMouseEnter={(e) => !isUploading && (e.currentTarget.style.opacity = '1')}
            onMouseLeave={(e) => !isUploading && (e.currentTarget.style.opacity = '0')}
          >
            {isUploading ? (
              <div className='d-flex flex-column align-items-center'>
                <Spinner />
                <span>Uploading...</span>
              </div>
            ) : (
              'Select new image'
            )}
          </div>
        </div>
      </div>

      {/* Upload button below the preview */}
      <div className='mt-3 row flex align-items-center'>
        <div className={`${!fileInputRef.current?.value ? 'col-12' : 'col-10'}`}>
          {fileInputRef.current?.value === '' || !previewImage || previewImage === initialImage ? (
            <Button
              variant={ButtonVariant.LIGHT}
              onClick={handleClick}
              disabled={isUploading || isMutating}
            >
              Select new image
            </Button>
          ) : (
            <Button
              size={ButtonSize.LARGE}
              onClick={handleUpload}
              disabled={isUploading || isMutating}
            >
              {buttonText}
            </Button>
          )}
        </div>
        {fileInputRef.current?.value && <div className='col-1'><IconButton icon={<TrashIcon height={30} width={30} />} onClick={removeCurrentImage} /></div>}
      </div>
    </div>
  )
}

export default ImageFilePicker
