import { NavLink, useNavigate } from 'react-router-dom'
import useSWR from 'swr'

import Step from '../../components/ui/stepper/step'
import { getClients } from '../../api'
import ClientForm from '../../components/clients/form'
import { parseClientToForm } from '../../lib/parsers'

const Step3NewClient = () => {
  const { data: clients, isLoading } = useSWR('/api/clients', getClients)
  const navigate = useNavigate()
  const onCancel = () => {
    navigate('/onboard-step-2')
  }
  const onSave = () => {
    navigate('/onboard-step-4')
  }
  const renderClientForm = () => {
    let hasSavedAClientAlready = !isLoading && clients?.length > 0
    return (
      <>
        <ClientForm
          onSave={onSave}
          onCancel={onCancel}
          saveLabel='Next'
          cancelLabel='Back'
          silent
          showFakeInvoice
          defaultValues={hasSavedAClientAlready ? parseClientToForm(clients[0]) : undefined}
          defaultPristine={!hasSavedAClientAlready}
        />
        {hasSavedAClientAlready ? null : (
          <div className='mt-2 text-end col-lg-6'>
            <NavLink to='/onboard-step-4' className='btn btn-link text-muted'>
              I'll add my client later
            </NavLink>
          </div>
        )}
      </>
    )
  }
  return (
    <Step
      subHeading={`Let's add your first client.`}
      current={3}
      total={4}
      loading={isLoading}
    >
      {renderClientForm()}
    </Step>
  )
}

export default Step3NewClient
