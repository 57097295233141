import React from 'react'
import useSWR, { SWRConfig } from 'swr'
import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import LoginPage from './pages/login'
import LandingPage from './pages/landing'
import DashboardPage from './pages/dashboard'
import { getCurrentUser, logout } from './api'
import { AuthContext, useAuth } from './context/auth'
import MasterPage from './pages/master'
import NewInvoicePage from './pages/invoices/new'
import ClientsPage from './pages/clients'
import UserProfile from './pages/user/profile'
import EditInvoicePage from './pages/invoices/edit'
import NewClientPage from './pages/clients/new'
import EditClientPage from './pages/clients/edit'
import { useAlerts } from './context/alerts'
import Step1Profile from './pages/onboard/step1Profile'
import Step4Final from './pages/onboard/step4Final'
import Step2Logo from './pages/onboard/step2Logo'
import Step3NewClient from './pages/onboard/step3NewClient'
import Spinner from './components/ui/spinner'
import Button from './components/ui/button'
import SendEmailPage from './pages/email'
import Terms from './pages/legal/terms'
import Privacy from './pages/legal/privacy'
import AdminPanel from './pages/admin'
import AdminPlayground from './pages/admin/playground'
import config from './config'
import BusinessLogo from './pages/user/business-logo'

function App() {
  const { addAlert } = useAlerts()
  const swrConfig = {
    onError: (error: any) => {
      addAlert({ type: 'error', message: error.message })
    }
  }

  return (
    <SWRConfig value={swrConfig}>
      <AuthProvider>
        <Routes>
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/' element={<LandingPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/signup' element={<LoginPage />} />
          <Route
            path='/onboard-step-1'
            element={(
              <RequireAuth>
                <MasterPage hideMenus header='Welcome to Tiny Invoice!'>
                  <Step1Profile />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/onboard-step-2'
            element={(
              <RequireAuth>
                <MasterPage hideMenus header='Welcome to Tiny Invoice!' backTo='/onboard-step-1'>
                  <Step2Logo />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/onboard-step-3'
            element={(
              <RequireAuth>
                <MasterPage hideMenus header='Welcome to Tiny Invoice!' backTo='/onboard-step-2'>
                  <Step3NewClient />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/onboard-step-4'
            element={(
              <RequireAuth>
                <MasterPage hideMenus header='All Done!' backTo='/onboard-step-3'>
                  <Step4Final />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/dashboard'
            element={(
              <RequireAuth>
                <MasterPage header='Your Invoices' actions={<NavLink to='/invoices/new'><Button>New Invoice</Button></NavLink>}>
                  <DashboardPage />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/invoices/new'
            element={(
              <RequireAuth>
                <MasterPage header='New Invoice' actions={<h3><small className='badge bg-light text-dark'>DRAFT</small></h3>}>
                  <NewInvoicePage />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/invoices/:id/send-email'
            element={(
              <RequireAuth>
                <MasterPage header='Send Email'>
                  <SendEmailPage />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/invoices/:id'
            element={(
              <RequireAuth>
                <MasterPage header='Edit Invoice'>
                  <EditInvoicePage />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/clients'
            element={(
              <RequireAuth>
                <MasterPage header='Your Clients' actions={<NavLink to='/clients/new'><Button>Create Client</Button></NavLink>}>
                  <ClientsPage />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/clients/new'
            element={(
              <RequireAuth>
                <MasterPage header='New Client'>
                  <NewClientPage />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/clients/:id'
            element={(
              <RequireAuth>
                <MasterPage header='Edit Client'>
                  <EditClientPage />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/profile/logo'
            element={(
              <RequireAuth>
                <MasterPage header='Your Business Logo'>
                  <BusinessLogo />
                </MasterPage>
              </RequireAuth>
            )}
          />
          <Route
            path='/profile'
            element={(
              <RequireAuth>
                <MasterPage header='Your Profile'>
                  <UserProfile />
                </MasterPage>
              </RequireAuth>
            )}
          />

          <Route
            path='/admin'
            element={(
              <RequireAuth>
                <RequireAdmin>
                  <MasterPage header='Admin'>
                    <AdminPanel />
                  </MasterPage>
                </RequireAdmin>
              </RequireAuth>
            )}
          />

          <Route
            path='/admin/playground'
            element={(
              <RequireAuth>
                <RequireAdmin>
                  <MasterPage header='Admin Playground'>
                    <AdminPlayground />
                  </MasterPage>
                </RequireAdmin>
              </RequireAuth>
            )}
          />

        </Routes>
      </AuthProvider>
    </SWRConfig>
  )
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let { user } = useAuth()
  let location = useLocation()
  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const currentPath = window.location.pathname;
    localStorage.setItem('auth_redirect_path', currentPath)
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return children
}


function RequireAdmin({ children }: { children: JSX.Element }) {
  let { user } = useAuth()
  if (!user.isAdmin) {
    return <Navigate to='/' replace />
  }
  return children
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { mutate, data, isLoading } = useSWR('/api/auth/user', getCurrentUser)

  let signin = () => {
    window.open(
      `${config.REACT_APP_API_URL}/api/auth/google?from=${encodeURIComponent(window.location.pathname)}`,
      '_self'
    )
  };

  let signout = async() => {
    await logout()
    mutate()
  };

  let value = { user: data?.data, signin, signout, mutate };
  if(isLoading) {
    return <Spinner />
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default App
